<template>
  <v-dialog v-model="dialog2" max-width="1000">
    <v-card>
      <v-card-title v-if="editedLineItem.id"> Edit</v-card-title>
      <v-card-title v-if="!editedLineItem.id"> Add</v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col cols="6">
            <v-text-field
              v-model="editedLineItem.Code"
              label="Code"
              required
            ></v-text-field>
            <v-text-field
              v-model="editedLineItem.Name"
              label="Name"
              required
            ></v-text-field>
            <v-text-field
              v-model="editedLineItem.AuthMth"
              label="Authentication Method"
              required
            ></v-text-field>
            <v-text-field
              v-model="editedLineItem.CallBackUrl"
              label="CallBackUrl"
              required
            ></v-text-field>

            <v-text-field
              v-model="editedLineItem.ValidationUrl"
              label="ValidationUrl"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="editedLineItem.ConfirmUrl"
              label="ConfirmUrl"
              required
            ></v-text-field>
            <v-checkbox
              label="Has StkPush"
              required
              v-model="editedLineItem.HasStkPush"
              :true-value="1"
              :false-value="0"
            ></v-checkbox>
            <v-text-field
              v-model="editedLineItem.StkPushURL"
              label="StkPushURL"
              required
            ></v-text-field>
            <v-text-field
              v-model="editedLineItem.QueryUrl"
              label="QueryUrl"
              required
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closeDialog"> Cancel </v-btn>
        <v-btn v-if="editedLineItem.id" color="blue darken-1" text @click="updateChanges">
          Update
        </v-btn>
        <v-btn v-if="!editedLineItem.id" color="blue darken-1" text @click="saveChanges"
          >save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    editedLineItem: {
      type: Object,
      required: true,
    },
    dialog2: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      valid: false,
    };
  },
  methods: {
    closeDialog() {
      this.$emit("close");
    },
    saveChanges() {
      this.$emit("savelines", this.editedLineItem);
    },
    updateChanges() {
      this.$emit("updatelines", this.editedLineItem);
    },
  },
};
</script>
