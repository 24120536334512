<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="secondary" flat dense>
        <v-toolbar-title>Payments</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>

      <v-card-text>
        <v-row dense>
          <v-col cols="6">
            <v-text-field dense v-model="search" label="Search"></v-text-field>
            <v-btn color="success" small @click="editItem(item)"> Add </v-btn>
            <v-data-table
              :loading="loading"
              loading-text="Loading... Please wait"
              :headers="headers"
              :items="record"
              :search="search"
            >
              <template v-slot:item.created_at="{ item }">{{
                item.created_at | moment("LLL")
              }}</template>

              <template v-slot:item.Active="{ item }">
                <v-btn class="btnLink" color="accent" text @click="changeStatus(item)">{{
                  item.Active == true ? "Active" : "In Active"
                }}</v-btn>
              </template>

              <template v-slot:item.id="{ item }">
                <v-btn color="primary" small @click="editItem(item)"> Edit </v-btn>
                <v-btn color="white mx-2" small @click="fetchPaymentTypes(item)">
                  <v-icon color="green">mdi-eye</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
          <v-col cols="6">
            <v-text-field
              dense
              v-model="selectedLine"
              readonly
              outlined
              label="Selected"
            ></v-text-field>
            <v-btn color="success" small @click="editLineItem(item)"> Add </v-btn>
            <v-data-table
              :loading="loading"
              loading-text="Loading... Please wait"
              :headers="headers"
              :items="paymentLines"
              :search="search"
            >
              <template v-slot:item.created_at="{ item }">{{
                item.created_at | moment("LLL")
              }}</template>

              <template v-slot:item.Active="{ item }">
                <v-btn class="btnLink" color="accent" text @click="changeStatus(item)">{{
                  item.Active == 1 ? "Active" : "In Active"
                }}</v-btn>
              </template>

              <template v-slot:item.id="{ item }">
                <v-btn color="primary" small @click="editLineItem(item)"> Edit </v-btn>
                <v-btn color="white mx-2" small @click="fetchPaymentAccounts(item)">
                  <v-icon color="green">mdi-eye</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-col>

          <v-col cols="12">
            <v-text-field
              dense
              v-model="selectedAcct"
              readonly
              outlined
              label="Selected"
            ></v-text-field>
            <v-btn color="success" small @click="editItem(item)"> Add </v-btn>
            <v-data-table
              :loading="loading"
              loading-text="Loading... Please wait"
              :headers="AccountsHeaders"
              :items="paymentAccounts"
              :search="search"
            >
              <template v-slot:item.created_at="{ item }">{{
                item.created_at | moment("LLL")
              }}</template>

              <template v-slot:item.Status="{ item }">
                <v-btn class="btnLink" color="accent" text @click="changeStatus(item)">{{
                  item.Status == 1 ? "Active" : "In Active"
                }}</v-btn>
              </template>

              <template v-slot:item.id="{ item }">
                <v-btn color="primary" small @click="editItem(item)"> Edit </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
      <edit-dialog
        :editedItem="editedItem"
        :dialog="dialog"
        @close="dialog = false"
        @update="updateItem"
        @save="saveItem"
      ></edit-dialog>
      <edit-line-dialog
        :editedLineItem="editedLineItem"
        :dialog2="dialog2"
        @close="dialog2 = false"
        @updatelines="updatelineItem"
        @savelines="savelineItem"
      ></edit-line-dialog>
      <snackbar ref="snackbar"></snackbar>
    </v-card>
  </v-container>
  <!-- snackbar -->
</template>

<script>
import EditDialog from "./_components/editor.vue";
import EditLineDialog from "./_components/lines-editor.vue";

export default {
  components: {
    EditDialog,
    EditLineDialog,
  },
  data: () => ({
    dialog: false,
    dialog2: false,
    editedItem: {},
    editedLineItem: {},
    valid: false,
    search: "",
    loading: false,
    record: [],
    paymentLines: [],
    PaymentLinesHeaders: [
      { text: "Key", value: "Code" },
      { text: "Label", value: "Name" },
      { text: "Status", value: "Active" },
      { text: "Actions", value: "id" },
    ],
    AccountsHeaders: [
      { text: "Key", value: "Code" },
      { text: "Shortcode", value: "Shortcode" },
      { text: "Status", value: "Status" },
      { text: "Username", value: "Username" },
      { text: "Password", value: "Password" },
      { text: "Actions", value: "id" },
    ],

    selectedLine: null,
    selectedAcct: null,
    paymentAccounts: [],
    headers: [
      { text: "Key", value: "Code" },
      { text: "Name", value: "Name" },
      { text: "Status", value: "Active" },
      { text: "Actions", value: "id" },
    ],
  }),
  methods: {
    getData() {
      const self = this;
      this.loading = true;
      this.$store.commit("loader", false);
      this.$store
        .dispatch("get", `/payments/incoming/third-party/modes`)
        .then((res) => {
          self.record = res.ResponseData;
          this.$store.commit("loader", false);
          this.loading = false;
        })
        .catch((err) => {
          this.$store.commit("loader", false);
          this.$refs.snackbar.show(err, "red");
        });
    },

    editItem(item) {
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    editLineItem(item) {
      this.editedLineItem = Object.assign({}, item);
      this.dialog2 = true;
    },
    fetchPaymentTypes(item) {
      console.log(item);
      this.selectedLine = item.Name;
      this.paymentLines = item.tpp1;
      this.paymentAccounts = [];
    },
    fetchPaymentAccounts(item) {
      console.log(item);
      this.selectedAcct = item.Name;
      this.paymentAccounts = item.tpp2;
    },
    closeDialog() {
      this.editedItem = {};
      this.dialog = false;
    },
    updateItem(item) {
      const self = this;
      const data = item;
      const url = `/payments/incoming/third-party/modes/${item.id}`;
      this.$store.commit("loader", true);
      this.$store
        .dispatch("put", { url, data })
        .then((res) => {
          if (res.ResultCode == 1200) {
            self.closeDialog();
            self.$refs.snackbar.show(res.ResultDesc, "green");
          } else {
            self.loading = false;
            this.$store.commit("loader", false);
            self.$refs.snackbar.show(res.ResultDesc, "red");
          }
          self.getData();
        })
        .catch((err) => {
          self.closeDialog();
          self.$refs.snackbar.show(err, "red");
          self.$store.commit("loader", false);
        });
    },

    saveItem(item) {
      const self = this;
      const data = item;
      const url = `/payments/incoming/third-party/modes`;
      this.$store.commit("loader", true);
      this.$store
        .dispatch("post", { url, data })
        .then((res) => {
          if (res.ResultCode == 1200) {
            self.closeDialog();
            self.$refs.snackbar.show(res.ResultDesc, "green");
          } else {
            self.loading = false;
            this.$store.commit("loader", false);
            self.$refs.snackbar.show(res.ResultDesc, "red");
          }
          self.getData();
        })
        .catch((err) => {
          self.closeDialog();
          self.$refs.snackbar.show(err, "red");
          self.$store.commit("loader", false);
        });
    },

    updatelineItem(item) {
      const self = this;
      const data = item;
      console.log(data);
      const url = `/payments/incoming/third-party/modes/lines/${item.id}`;
      this.$store.commit("loader", true);
      this.$store
        .dispatch("put", { url, data })
        .then((res) => {
          if (res.ResultCode == 1200) {
            self.closeDialog();
            self.$refs.snackbar.show(res.ResultDesc, "green");
          } else {
            self.loading = false;
            this.$store.commit("loader", false);
            self.$refs.snackbar.show(res.ResultDesc, "red");
          }
          self.getData();
        })
        .catch((err) => {
          self.closeDialog();
          self.$refs.snackbar.show(err, "red");
          self.$store.commit("loader", false);
        });
    },

    savelineItem(item) {
      const self = this;
      item.DocEntry = this.paymentLines[0].id;
      const data = item;
      console.log(this.paymentLines[0]);
      console.log(data);
      const url = `/payments/incoming/third-party/modes/lines`;
      this.$store.commit("loader", true);
      this.$store
        .dispatch("post", { url, data })
        .then((res) => {
          if (res.ResultCode == 1200) {
            self.closeDialog();
            self.$refs.snackbar.show(res.ResultDesc, "green");
          } else {
            self.loading = false;
            this.$store.commit("loader", false);
            self.$refs.snackbar.show(res.ResultDesc, "red");
          }
          self.getData();
        })
        .catch((err) => {
          self.closeDialog();
          self.$refs.snackbar.show(err, "red");
          self.$store.commit("loader", false);
        });
    },
  },
  created() {
    this.getData();
  },
};
</script>
