<template>
  <v-dialog v-model="dialog" max-width="500">
    <v-card>
      <v-card-title v-if="editedItem.id"> Edit</v-card-title>
      <v-card-title v-if="!editedItem.id"> Add</v-card-title>
      <v-card-text>
        <v-form ref="editForm" v-model="valid">
          <v-text-field v-model="editedItem.Code" label="Code" required></v-text-field>
          <v-text-field v-model="editedItem.Name" label="Name" required></v-text-field>
          <v-text-field
            v-model="editedItem.Description"
            label="Description"
            required
          ></v-text-field>
          <v-checkbox
            label="Active"
            required
            v-model="editedItem.Active"
            :true-value="1"
            :false-value="0"
          ></v-checkbox>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closeDialog"> Cancel </v-btn>
        <v-btn
          v-if="editedItem.id"
          color="blue darken-1"
          text
          @click="updateChanges"
          :disabled="!valid"
        >
          Update
        </v-btn>
        <v-btn
          v-if="!editedItem.id"
          color="blue darken-1"
          text
          @click="saveChanges"
          :disabled="!valid"
          >save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    editedItem: {
      type: Object,
      required: true,
    },
    dialog: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      valid: false,
    };
  },
  methods: {
    closeDialog() {
      this.$emit("close");
    },
    saveChanges() {
      this.$emit("save", this.editedItem);
    },
    updateChanges() {
      this.$emit("update", this.editedItem);
    },
  },
};
</script>
